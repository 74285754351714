.conf-nav-title {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: $dark-gray;
  font-weight: bold;
  line-height: 1;
  padding-left: 0.9rem;
  margin-bottom: 0.5rem;
  border-top: 1px solid $medium-gray;
  margin-top: 1rem;
  padding-top: 1.5rem; }
  
// Magellan sidebar menu

ul.menu a.active {
  background-color: $menu-item-background-active;
  color: $white; }



