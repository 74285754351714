// Use the breakpoint mixin to make headings bold on mobile screens so that they are clearly distinguishable from normal paragraphs
h1,
h2,
h3,
h4
{
    @include breakpoint(small only) {
      font-weight: bold;
    }
}

.featurePanel
{
	background-color: $feature-panel-background;
	padding: $global-padding;
}

// Menu text beside Menu button on Title Bar
span.menu-link
{
	color:$white;
	font-variant: small-caps;
}

/* Make sticky title bar 100% of the screen width */
.sticky {
  width: 100%; }

p.hr img
{
	width: 100%;
	height: 3px;
}

a
{
	font-weight: bold;
}

ul.menu a
{
	font-weight: normal;
	font-size: 0.95rem;
}

ul.menu > li > a
{
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
}

a:hover
{
	text-decoration: underline;
}

footer
{
	font-size: 1rem;
	text-align: center;
	background-color: $primary-color;
	color: $white;
	padding: $global-padding;
}

footer a,
footer a:link,
footer a:visited
{
	color: $white;
}

footer a:active,
footer a:hover
{
	color: $light-gray;
	text-decoration: underline;
}

a.DropDown:active,
a.DropDown:hover
{
	text-decoration: underline;
}
	
.collapsed
{
	display: none;
}	

.expanded
{
	display: block;
	position: relative;
	left: 1rem;
}	

p.byline
{
	font-style: italic;
}

/*-----------------------------------FlexBox Styles--------------------------------------------*/

div.SpeakerList
{
	display: flex;
	flex-direction: row; 			/* row | column | row-reverse | column-reverse */	
	flex-wrap: wrap;				/* wrap | nowrap | wrap-reverse */
	justify-content: space-between;	/* flex-start | flex-end | center | space-between | space-around */
	align-items: stretch;			/* stretch | flex-start | flex-end | center | baseline */
	align-content: stretch;			/* stretch | flex-start | flex-end | center | space-between | space-around */
	margin-right: -10px;
}

div.Speaker
{
	order: 0;
	flex-grow: 0;					/* 0 means it will not be allowed to grow */
	flex-shrink: 1;					/* 0 means it will not be allowed to shrink */
	flex-basis: 90px;				/* auto means it will be sized automatically based on its width - when would you use 0? */
	align-self: auto;				/* auto | flex-start | flex-end | center | baseline | stretch */
	margin-right: 10px;
}

div.SessionList
{
	display: flex;
	flex-direction: row; 			/* row | column | row-reverse | column-reverse */	
	flex-wrap: nowrap;				/* wrap | nowrap | wrap-reverse */
	justify-content: flex-start;	/* flex-start | flex-end | center | space-between | space-around */
	align-items: stretch;			/* stretch | flex-start | flex-end | center | baseline */
	align-content: stretch;			/* stretch | flex-start | flex-end | center | space-between | space-around */
}

div.Session
{
	order: 0;
	flex-grow: 1;					/* 0 means it will not be allowed to grow - numbers relate to proportionally how much of the available space an element can take as additional width*/
	flex-shrink: 1;					/* 0 means it will not be allowed to shrink */
	flex-basis: 40%;				/* auto means it will be sized automatically based on its width - when would you use 0? */
	align-self: auto;				/* auto | flex-start | flex-end | center | baseline | stretch */
	margin: 5px;
}

/*-----------------------------------CSS Masterclass Styles--------------------------------------------*/

.DateLocation
{
	font-weight: bold;
	font-size: 1.3em;
	color: $primary-color;
}